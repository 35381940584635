//
// tables.scss
//

//Table centered (Custom)
.table-centered {
  th,
  td {
    vertical-align: middle !important;
  }
}

// Custom table components (Custom)
.table {
  .table-user {
    img {
      height: 30px;
      width: 30px;
    }
  }

  .action-icon {
    color: var(--#{$prefix}gray-600);
    font-size: 1.2rem;
    display: inline-block;
    padding: 0 3px;

    &:hover {
      color: var(--#{$prefix}gray-700);
    }
  }

  > :not(caption) > * > * {
    background-color: var(--#{$prefix}table-bg) !important;
  }

  &.table-bordered {
    tbody {
      border-top: $border-width solid;
      border-top-color: inherit;
    }
  }
}

.table-sm {
  .action-icon {
    font-size: 1rem;
  }
}

// Tables fluid
.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

// Dark Mode light and dark table
html[data-bs-theme="dark"] {
  .table-light {
    --#{$prefix}table-bg: #3e4954;
    --#{$prefix}table-border-color: #{$table-group-separator-color};
    color: var(--#{$prefix}body-color);
  }

  .table-dark {
    --#{$prefix}table-bg: var(--#{$prefix}light);

    tbody,
    tr {
      border-color: var(--#{$prefix}gray-300);
    }
  }
}
