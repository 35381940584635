//
// datatable.scss
//

.dataTables_wrapper {
  &.container-fluid {
    padding: 0;
  }
}

table.dataTable {
  border-collapse: collapse !important;
  margin-bottom: 15px !important;

  &.dtr-inline {
    &.collapsed {
      > tbody {
        > tr {
          > td,
          th {
            &.dtr-control {
              &:before {
                background-color: $primary;
                box-shadow: 0px 0px 2px 0px $primary;
              }
            }
          }
        }
      }
    }
  }
  thead:not.table-light {
    th {
      background-color: transparent;
      border-bottom-color: var(--#{$prefix}border-color);
    }
  }
  tbody {
    tr.even,
    tr.odd {
      td {
        background-color: transparent;
      }
    }
  }

  tbody {
    > tr {
      &.selected {
        td {
          background-color: $primary;
        }
      }
    }
  }

  &.table-striped > tbody > tr.selected:nth-of-type(odd) > * {
    --ct-table-accent-bg: $primary !important;
  }

  // Change icons view
  thead {
    .sorting,
    .sorting_asc,
    .sorting_desc,
    .sorting_asc_disabled,
    .sorting_desc_disabled {
      &:before {
        right: 0.5rem;
        left: auto;
        content: "\F0360";
        font-family: "Material Design Icons";
        font-size: 1rem;
        top: 12px;
      }
      &:after {
        right: 0.5em;
        left: auto;
        content: "\F035D";
        font-family: "Material Design Icons";
        top: 18px;
        font-size: 1rem;
      }
    }
    > tr {
      > th {
        &.sorting_asc,
        &.sorting_desc,
        &.sorting {
          padding-right: 30px;
          padding-left: $table-cell-padding-x;
        }
      }
    }
  }

  tbody {
    // Multi select table

    > tr.selected,
    > tr > .selected {
      background-color: $primary;

      td {
        border-color: $primary;
      }
    }
    td {
      &:focus {
        outline: none !important;
      }
    }
    // Key Tables
    th.focus,
    td.focus {
      outline: 2px solid $primary !important;
      outline-offset: -1px;
      background-color: rgba(var(--#{$prefix}primary-rgb), 0.15);
    }
  }
}

.dataTables_info {
  font-weight: $font-weight-semibold;
}

.dtfh-floatingparent {
  top: $topbar-height !important;
}

// Responsive data table
table.dataTable.dtr-inline.collapsed {
  > tbody {
    > tr[role="row"] {
      > td,
      > th {
        &:first-child {
          &:before {
            box-shadow: var(--#{$prefix}box-shadow-lg);
            background-color: $success;
            top: auto;
            bottom: auto;
          }
        }
      }
    }
    > tr.parent {
      > td,
      > th {
        &:first-child {
          &:before {
            background-color: $danger;
          }
        }
      }
    }
  }
}

// Data Table copy button
div.dt-button-info {
  background-color: $primary;
  border: none;
  color: $white;
  box-shadow: none;
  border-radius: 3px;
  text-align: center;
  z-index: 21;

  h2 {
    border-bottom: none;
    background-color: rgba($white, 0.2);
    color: $white;
  }
}

@include media-breakpoint-down(md) {
  li.paginate_button.previous,
  li.paginate_button.next {
    display: inline-block;
    font-size: 1.5rem;
  }

  li.paginate_button {
    display: none;
  }
  .dataTables_paginate {
    ul {
      text-align: center;
      display: block;
      margin: $spacer 0 0 !important;
    }
  }
  div.dt-buttons {
    display: inline-table;
    margin-bottom: $spacer;
  }
}

// Active status
.activate-select {
  .sorting_1 {
    background-color: var(--#{$prefix}gray-100) !important;
  }
}

// datatable
div.dataTables_wrapper {
  div.dataTables_filter {
    text-align: right;

    @media (max-width: 576px) {
      text-align: center;
    }

    input {
      margin-left: 0.5em;
      margin-right: 0;
    }
  }
  div.dataTables_length {
    text-align: left;
    @media (max-width: 576px) {
      text-align: center;
    }
  }
}

div.table-responsive > div.dataTables_wrapper {
  > div.row > div[class^="col-"]:last-child {
    padding-right: 0;
  }
  > div.row > div[class^="col-"]:first-child {
    padding-left: 0;
  }
}

div {
  &.dataTables_scrollBody {
    border-left: none !important;
    & > table {
      margin-bottom: 15px !important;
      & > :not(:first-child) {
        border-top: none !important;
      }
    }
  }
}
