//
// colorpicker.scss
//

.colorpicker {
  background: $dropdown-bg;
  box-shadow: var(--#{$prefix}box-shadow);
  border: 1px solid $dropdown-border-color;
}

.sp-picker-container {
  .sp-alpha-handle {
    right: 0;
    left: auto;
  }
}
