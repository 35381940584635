//
// custom-variables.scss
//

$logo-lg-height: var(--#{$prefix}logo-lg-height);
$logo-sm-height: var(--#{$prefix}logo-sm-height);

// Left Sidebar
$leftbar-width: var(--#{$prefix}leftbar-width);
$leftbar-width-md: var(--#{$prefix}leftbar-width-md);
$leftbar-width-sm: var(--#{$prefix}leftbar-width-sm);
$leftbar-condensed-height: var(--#{$prefix}leftbar-condensed-height);

// Two Column sidebar
$twocolumn-sidebar-width: var(--#{$prefix}twocolumn-sidebar-width);
$twocolumn-sidebar-iconview-width: var(
  --#{$prefix}twocolumn-sidebar-iconview-width
);
$twocolumn-sidebar-iconview-size: var(
  --#{$prefix}twocolumn-sidebar-iconview-size
);
$twocolumn-sidebar-iconview-bg: var(--#{$prefix}twocolumn-sidebar-iconview-bg);

$menu-link-item-gap: var(--#{$prefix}menu-link-item-gap);

$menu-item-padding-x: var(--#{$prefix}menu-item-padding-x);
$menu-item-gap: var(--#{$prefix}menu-item-gap);

$menu-link-padding-y: var(--#{$prefix}menu-link-padding-y);
$menu-link-padding-x: var(--#{$prefix}menu-link-padding-x);

$menu-item-icon-size: var(--#{$prefix}menu-item-icon-size);
$menu-item-font-size: var(--#{$prefix}menu-item-font-size);

// Menu background and text color
$menu-bg: var(--#{$prefix}menu-bg);
$menu-item-color: var(--#{$prefix}menu-item-color);
$menu-item-hover-color: var(--#{$prefix}menu-item-hover-color);
$menu-item-active-color: var(--#{$prefix}menu-item-active-color);
$menu-item-active-bg: var(--#{$prefix}menu-item-active-bg);
$menu-icon-twotone-fill: var(--#{$prefix}menu-icon-twotone-fill);

// Menu Gradirnt image
$menu-gradient-image: var(--#{$prefix}menu-gradient-image);

// Horizontal Menu
$horizontal-menu-height: var(--#{$prefix}horizontal-menu-height);

// Topbar
$topbar-height: var(--#{$prefix}topbar-height);
$topbar-bg: var(--#{$prefix}topbar-bg);
$topbar-item-color: var(--#{$prefix}topbar-item-color);
$topbar-item-hover-color: var(--#{$prefix}topbar-item-hover-color);
$topbar-search-bg: var(--#{$prefix}topbar-search-bg);

// Footer
$footer-height: var(--#{$prefix}footer-height);

// Menu Trasition
$menu-transition: var(--#{$prefix}menu-transition);

// Two Column Sidebar Background and Text color
$twocolumn-iconview-bg: var(--#{$prefix}twocolumn-iconview-bg);
$twocolumn-iconview-item-color: var(--#{$prefix}twocolumn-iconview-item-color);
$twocolumn-iconview-item-hover-color: var(
  --#{$prefix}twocolumn-iconview-item-hover-color
);
$twocolumn-iconview-item-active-color: var(
  --#{$prefix}twocolumn-iconview-item-active-color
);
$twocolumn-iconview-icon-active-bg: var(
  --#{$prefix}twocolumn-iconview-icon-active-bg
);

// Boxed Layout
$boxed-layout-width: var(--#{$prefix}boxed-layout-width);
