//
// kanbanboard.scss
//

.tasklist {
  min-height: 40px;
  margin-bottom: 0;

  li {
    background-color: $card-bg;
    border: 1px solid var(--#{$prefix}gray-300);
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 3px;
    box-shadow: $box-shadow;

    &:last-of-type {
      margin-bottom: 0;
    }

    .btn-sm {
      padding: 2px 8px;
      font-size: 12px;
    }
  }

  .form-check {
    margin-left: 20px;
    margin-top: 5px;
    .form-check-input {
      margin-left: 0;
    }
  }
}

.task-placeholder {
  border: 1px dashed var(--#{$prefix}gray-300) !important;
  background-color: var(--#{$prefix}gray-100) !important;
  padding: 20px;
}
