//
// authentication.scss
//

.bg-pattern {
  background-image: url("../../../images/bg-pattern-2.png");
  background-size: cover;
}

// authentication pages background
body {
  &.authentication-bg {
    background-color: #00acc1;
    background-size: cover;
    background-position: center;

    footer {
      background-color: transparent !important;
    }
  }

  &.authentication-bg-pattern {
    background-image: url("../../../images/bg-pattern.png");
  }
}

// Logout page
.logout-icon {
  width: 140px;
}

// Authentication fluid
.auth-fluid {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 100vh;
  flex-direction: row;
  align-items: stretch;
  background: url("../../../images/bg-auth.jpg") center;
  background-size: cover;

  .auth-fluid-form-box {
    max-width: 480px;
    border-radius: 0;
    z-index: 2;
    padding: 3rem;
    background-color: $card-bg;
    position: relative;
    width: 100%;
  }

  .auth-fluid-right {
    padding: 6rem 3rem;
    flex: 1;
    position: relative;
    color: $white;
    background-color: rgba($black, 0.3);
  }
}

.auth-brand {
  margin-bottom: 2rem;
}

.auth-user-testimonial {
  position: absolute;
  margin: 0 auto;
  padding: 0 1.75rem;
  bottom: 3rem;
  left: 0;
  right: 0;

  p.lead {
    font-size: 1.125rem;
    margin: 0 auto 20px auto;
    max-width: 700px;
  }
}

@include media-breakpoint-down(lg) {
  .auth-fluid {
    display: block;

    .auth-fluid-form-box {
      max-width: 100%;
      min-height: 100vh;
    }

    .auth-fluid-right {
      display: none;
    }
  }
}

.auth-brand {
  margin-bottom: 2rem;

  .logo-dark {
    display: block;
  }

  .logo-light {
    display: none;
  }
}

// Dark mode
html[data-bs-theme="dark"] {
  .auth-brand {
    .logo-light {
      display: block;
    }

    .logo-dark {
      display: none;
    }
  }
}
