//
// Selectize.scss
//

.selectize-input {
  min-height: $input-height;
  padding: $input-padding-y $input-padding-x;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg !important;
  border: $input-border-width solid $input-border-color;
  box-shadow: var(--#{$prefix}box-shadow-sm);

  > input {
    color: $input-color;

    &::placeholder {
      color: $input-placeholder-color;
    }
  }

  &.focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    box-shadow: none !important;
  }
}

.selectize-control {
  &.multi {
    .selectize-input > div {
      padding: 1px 8px;
      background-color: var(--#{$prefix}gray-300);
      color: var(--#{$prefix}gray-800);

      > a {
        color: var(--#{$prefix}gray-800);
      }

      &.active {
        background: $primary;
        > a {
          color: color-yiq($primary);
        }
      }
    }
  }

  &.single {
    .selectize-input {
      &:after {
        border-style: solid;
        border-width: 0 2px 2px 0;
        border-color: transparent var(--#{$prefix}gray-500)
          var(--#{$prefix}gray-500) transparent;
        content: "";
        display: block;
        height: 7px;
        margin-top: -5px;
        pointer-events: none;
        position: absolute;
        right: 15px;
        left: auto;
        top: 50%;
        transform-origin: 66% 66%;
        transform: rotate(45deg);
        transition: all 0.15s ease-in-out;
        width: 7px;
      }

      &.dropdown-active:after {
        border-width: 0 2px 2px 0;
        margin-top: -5px;
        border-color: transparent var(--#{$prefix}gray-500)
          var(--#{$prefix}gray-500) transparent;
        transform: rotate(-135deg);
      }
    }
  }
}

.selectize-dropdown {
  padding: 0.3rem;
  color: $dropdown-color;
  box-shadow: var(--#{$prefix}box-shadow);
  background-color: $dropdown-bg;
  background-clip: padding-box;
  border: $dropdown-border-width solid $dropdown-border-color;
  @include border-radius($dropdown-border-radius);
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  margin: 0;
  font-size: 0.875rem;
  position: absolute;
  z-index: 1000;

  &.show {
    top: 100% !important;
  }

  &.active {
    color: $dropdown-link-hover-color;
    @include gradient-bg($dropdown-link-hover-bg);
  }

  .scientific {
    color: var(--#{$prefix}gray-600);
  }

  .option,
  .optgroup-header {
    display: block;
    width: 100%;
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    clear: both;
    cursor: pointer;
    font-weight: $font-weight-normal;
    color: $dropdown-link-color;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;

    &:hover,
    &:focus {
      color: $dropdown-link-hover-color;
      text-decoration: none;
      background-color: $dropdown-link-hover-bg !important;
    }
  }

  &.plugin-optgroup_columns {
    .optgroup {
      border-right-color: var(--#{$prefix}gray-300);
    }
  }

  .optgroup:before {
    background-color: var(--#{$prefix}gray-300);
  }
}

.selectize-dropdown-header {
  border-bottom: 1px solid transparent;
  color: $dropdown-link-active-color;
  background-color: $dropdown-link-active-bg;
  text-decoration: none;
  .selectize-dropdown-header-close {
    right: 12px;
    left: auto;
  }
}

.selectize-dropdown-content {
  > div {
    background-color: transparent !important;
    color: $input-color !important;
  }
}
