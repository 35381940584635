//
// coming-soon.scss
//

.counter-number {
  font-size: 32px;
  font-weight: $font-weight-bold;
  color: $white;
  span {
    font-size: 15px;
    font-weight: $font-weight-normal;
    display: block;
  }
}

.coming-box {
  float: left;
  width: 25%;
}

.svg-rocket {
  height: 80px;
}

.rocket-clouds__bubble,
.rocket-clouds__cloud,
.rocket-rocket,
.rocket-inner__rocket-and-lines {
  fill: $white;
}
